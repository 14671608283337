<template>
  <div>
    <div class="login-cover">
      <div class="login-cover-bg"></div>
    </div>
    <div class="login login-v2" data-pageload-addclass="animated fadeIn">
      <div class="login-header">
        <div class="brand">
          <span class="logo">
            <router-link to="/">
              <img :src="logoImageCyphio" v-if="domain === 'cyphio.com'" alt="" class="img-fluid"/>
              <img :src="logoImageResiMe" v-if="domain === 'resi-me.si'" alt="" class="img-fluid"/>
              <img :src="logoImageCyphio" v-if="domain === 'localhost'" alt="" class="img-fluid"/>
            </router-link>
          </span>
        </div>
      </div>
      <div class="login-content">
        <div class="form-group" v-if="isReset">
          <div class="alert alert-warning login-expired" role="alert">
            {{ $t("common.logIn.expired") }}
          </div>
        </div>
        <div id="googleLoginButton" class="d-flex justify-content-center m-b-35"></div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions} from "vuex";
import PageOptions from "../../config/PageOptions.vue";
import {getDomain, getLocalStorageUserItemName} from "@/_helpers/domain_helper";
import {getLoginButtonLocale} from "@/_helpers/locale_helper";

export default {
  name: "Login",
  data() {
    return {
      googleClientId: process.env.VUE_APP_G_AUTH_CLIENT_ID,
      logoImageResiMe: require("@/assets/images/logo-resi-me.si.webp"),
      logoImageCyphio: require("@/assets/images/logo-cyphio.com.webp"),
    }
  },
  computed: {
    ...mapState("account", ["status"]),
    isReset() {
      return "reset" in this.$route.query && this.$route.query.reset;
    },
    domain() {
      return getDomain()
    }
  },
  created() {
    PageOptions.pageEmpty = true;
    this.checkIfReset();
  },
  beforeRouteLeave(to, from, next) {
    PageOptions.pageEmpty = false;
    next();
  },
  methods: {
    ...mapActions("account", ["loginGoogleOauth2"]),
    checkIfReset() {
      if (this.isReset) {
        localStorage.removeItem(getLocalStorageUserItemName());
      }
    },
    handleCredentialResponse(response) {
      this.loginGoogleOauth2(response.credential)
    }
  },
  mounted() {
    // eslint-disable-next-line no-undef
    google.accounts.id.initialize({
      client_id: process.env.VUE_APP_G_AUTH_CLIENT_ID,
      callback: this.handleCredentialResponse
    });
    // eslint-disable-next-line no-undef
    google.accounts.id.renderButton(
      // customization attributes: https://developers.google.com/identity/gsi/web/reference/html-reference#element_with_class_g_id_signin
      document.getElementById("googleLoginButton"),
      {
        theme: "filled_black",
        size: "large",
        locale: getLoginButtonLocale(),
      }
    );
  }
};
</script>

<style scoped></style>
